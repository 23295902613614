import React from 'react';
import {Grid, withStyles} from "@material-ui/core";
import trustFF1 from "../../assets/images/trust_ff1.svg";
import trustGermany from "../../assets/images/trust_made_in_germany.svg";

const styles = theme => ({
    logosContainer: {
        // background: FF1Colors.lightestGray,
        // minHeight: "10vh",
        // paddingBottom: "2rem",
        // maxWidth: '550px',
        // margin: '0 auto',
        justifyContent: "flex-end",
        alignItems: "center",
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logosImg: {
        display: 'block',
        maxHeight: 70,
        maxWidth: '100%',
        objectFit: 'contain',
        margin: '10px auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 35,
            margin: 10,
        },
    },
})

const TrustLogos = (props) => {
    const {classes} = props;

    return (
        <Grid container className={classes.logosContainer}>
            <Grid item xs={6} sm={3} className={classes.logoContainer}>
                <img src={trustGermany} className={classes.logosImg}
                     alt={'Made and hosted in Germany'}/>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.logoContainer}>
                <img src={trustFF1} className={classes.logosImg} alt={'Fair Force One'}/>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(TrustLogos);
;