import React, {useEffect, useState} from 'react';
import {withStyles, Typography, Grid, Paper} from '@material-ui/core';
import axios from 'axios';
import {withRouter} from 'react-router';
import {withSnackbar} from 'notistack';
import personalRecommandationBadge from '../../assets/images/Logo_Empfehlung.png';
import WhatsAppLogo from '../../assets/images/WhatsApp_Logo.png';
import TelegramLogo from '../../assets/images/Telegram_Logo.png';
import MailAppLogo from '../../assets/images/MailApp_Logo.png';
import Button from "@material-ui/core/Button";

const styles = theme => ({
    paper: {
        padding: theme.spacing(3),
        maxWidth: '960px',
        margin: '0 auto',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.only('xs')]: {
            padding: 4,
        },
    },
    introContainer: {
        flexDirection: "row",
        flexWrap: "nowrap",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
            textAlign: "center",
        },
    },
    specialistLogo: {
        maxWidth: 150,
        height: "auto",
        margin: 7,
        [theme.breakpoints.down('md')]: {
            maxWidth: 100,
        }
    },
    logo: {
        width: 64,
        marginRight: 10,
    },
    quote: {
        marginLeft: 30,
    }
});

const Recommendations = props => {
    const {classes} = props;

    const message = `Hallo,
hier meine Empfehlung für Deinen Verkehrsunfall. 
Ich war vor Kurzem auch in dieser Situation und bin dabei auf fairforce.one gestoßen. Die haben sich für mich um alles gekümmert und das Beste für mich rausgeholt.
Hat bei mir top funktioniert und mich keinen Cent gekostet. 
Einfach über den Link dort melden und die helfen Dir direkt.`;

    const emailSubject = "Du hattest einen Unfall? fairforce.one hat mir geholfen";

    const [c2clink, setC2CLink] = useState();

    useEffect(() => {

        axios({
            url: `/kisapi/c2clink`,
            method: 'get',
            headers: {
                Authorization: JSON.parse(sessionStorage.getItem('token')),
            },
        })
            .then((response) => {
                console.log(props.baseUrl)
                console.log(window.location.origin + response.data.link)
                setC2CLink(window.location.origin + response.data.link)
            })
    }, []);

    return (
        <React.Fragment>
            <Grid container justify='center'>
                <Grid item xs={12} lg={9}>
                    <Paper className={classes.paper}>

                        <Grid container spacing={5}>

                            <Grid item xs={12}>
                                <Grid container className={classes.introContainer}>
                                    <Grid item>
                                        <Grid container justify={"center"}>
                                            <img src={personalRecommandationBadge} className={classes.specialistLogo}
                                                 alt={'specialist'}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h3">Erfahrung teilen und „DANKE MITDENKER!“- Prämie
                                            sichern!</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12}>
                                <Grid container>
                                    {c2clink && <ShareSection c2clink={c2clink} classes={classes} message={message}
                                                              emailSubject={emailSubject}/>}
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography className={classes.quote}>
                                    <em>Ein unabhängiges <strong>Spezialisten-Netzwerk</strong>, das 100% auf meiner
                                        Seite steht, mir schnell
                                        und transparent hilft, sich engagiert um alles kümmert und <strong>ohne jedes
                                            Kostenrisiko </strong>
                                        für mich nutzbar ist?</em>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <IntroTextSection/>
                            </Grid>

                            <Grid item xs={12}>
                                <ExplainSection/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

function IntroTextSection() {
    const getCaseDetails = () => {
        if (sessionStorage.getItem('caseDetails')) {
            console.info(JSON.parse(sessionStorage.getItem('caseDetails')));
            const obj = JSON.parse(sessionStorage.getItem('caseDetails'))
            return {
                sa: obj.sa ? obj.sa : null,
                fn: obj.fn ? obj.fn : null,
                ln: obj.ln ? obj.ln : null,
            }
        } else {
            return undefined;
        }
    };

    return (
        <div>

            <Typography>
                <p>
                    Ja, das klingt wohl fast zu gut. Da sind viele Unfallgeschädigte erst einmal
                    verunsichert und suchen den Haken an der Sache.
                </p>
            </Typography>

            <Typography>
                <p>
                    Sie, <span>{getCaseDetails().sa} {getCaseDetails().fn} {getCaseDetails().ln}</span> , wissen jetzt,
                    dass es kein leeres Werbeversprechen ist. Sie
                    wissen <strong>aus eigener Erfahrung</strong>, dass genau das unsere <strong>Gründungsidee und
                    täglich
                    gelebter Service ist</strong>
                </p>
            </Typography>

            <Typography>
                <p>
                    <strong>Machen Sie bitte etwas Gutes aus diesem Wissen! Teilen Sie es!</strong>
                </p>
            </Typography>

            <Typography>
                <p>
                    Sie helfen damit nicht nur Ihren Freunden und Bekannten in der Sondersituation
                    Unfall. Sie helfen damit gleichzeitig auch unserem jungen Unternehmen sichtbarer
                    zu werden. Und nicht zuletzt machen Sie damit die Welt nach Unfall ein Stück
                    fairer.
                </p>
            </Typography>

            <Typography>
                <p>
                    <strong>

                        Dafür bedanken wir uns mit 15,- € Gutschrift auf Ihrem Girokonto.

                    </strong>
                </p>
            </Typography>

            <Typography>
                <p>

                    Danke & herzliche Grüße aus Leipzig

                </p>
                <p>
                    <em>

                        Ihr Team von fairforce.one

                    </em>
                </p>
            </Typography>
        </div>
    )
}

function ShareSection({c2clink, classes, message, emailSubject}) {
    return (
        <Grid item xs={12}>
            <Grid container justify="space-evenly" spacing={2}
                  alignItems="center">
                <Grid item>
                    <a style={{textDecoration: 'none'}}
                       href={'https://wa.me?text=' + encodeURI(message + ": " + c2clink)}>
                        <Button variant="outlined">
                            <img src={WhatsAppLogo} className={classes.logo}
                                 alt={'WhatsApp'}/>
                            WhatsApp
                        </Button>
                    </a>
                </Grid>
                <Grid item>
                    <a style={{textDecoration: 'none'}}
                       href={'https://telegram.me/share/url?url=' + encodeURI(c2clink) + '&text=' + encodeURI(message + ".")}>
                        <Button variant="outlined">
                            <img src={TelegramLogo} className={classes.logo} alt={'Telegram'}/>
                            Telegram
                        </Button>
                    </a>
                </Grid>
                <Grid item>
                    <a style={{textDecoration: 'none'}}
                       href={'mailto:?subject=' + encodeURI(emailSubject) + '&body=' + encodeURI(message + ": " + c2clink)}>
                        <Button variant="outlined" fullWidth>
                            <img src={MailAppLogo} className={classes.logo} alt={'E-Mail'}/>
                            E-Mail
                        </Button>
                    </a>
                </Grid>
            </Grid>
        </Grid>
    )
}

function ExplainSection() {
    return (
        <div>
            <Typography variant="h4">
                So einfach geht´s:
            </Typography>

            <Typography>
                <p>
                    Ihr personalisierter Empfehlungs-Link wurde bereits automatisch für sie erzeugt.
                </p>
            </Typography>

            <Typography>
                <p>
                    Sie wählen aus, ob sie ihren personalisierten Empfehlungs-Link über WHATSAPP, TELEGRAM oder EMAIL
                    verschicken.
                </p>
            </Typography>

            <Typography>
                <p>
                    Die von Ihnen adressierte verunfallte Person kann direkt über den Link die faire Regulierung über fairforce.one
                    beauftragen. Bei Eingang eines Auftrages aus diesem Link kann fairforce.one den neuen Kunden als
                    Ihre Empfehlung zuordnen. Sie bekommen binnen 3 Werktagen ab Auftragseingang eine <strong>Gutschrift
                    über 15,- €</strong> in ihr o.g. Girokonto.
                </p>
            </Typography>

            <Typography>
                <p>
                    WICHTIG: Ihr persönlicher Empfehlungslink bleibt gültig und sie können ihn beliebig oft zu den
                    beschriebenen Konditionen weitergeben.
                </p>
            </Typography>

            <Typography>
                <p>
                    Von der Teilnahme ausgeschlossen sind die Mitarbeiter von fairforce.one und jedweder
                    Netzwerkpartner.
                </p>
            </Typography>
        </div>
    )
}

export default withSnackbar(withRouter(withStyles(styles)(Recommendations)));
