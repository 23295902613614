import React, {Component} from 'react';
import {Button, Card, CardContent} from '@material-ui/core';
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import IframeResizer from 'iframe-resizer-react'

const styles = theme => ({
    privacyCard: {
        minHeight: '95vh'
    },
    staticBackButton: {
        position: 'fixed',
        bottom: '2.5rem',
        left: '50%',
        transform: 'translate(-50%,0)'
    },
    legalIFrame: {
        width: "100%",
        border: "none",
        minHeight: "80vh",
        marginBottom: 50
    }
});

class Privacy extends Component {
    render() {
        const {classes} = this.props;

        return (
            <Card className={classes.privacyCard}>
                <CardContent>
                    <IframeResizer
                        className={classes.legalIFrame}
                        src={"https://fairforce.one/meta/datenschutzerklarung-extern/"}
                    ></IframeResizer>
                    <Button className={classes.staticBackButton}
                            onClick={() => this.props.history.goBack()}>zurück</Button>

                </CardContent>
            </Card>
        );
    };
}

export default withRouter(withStyles(styles)(Privacy));
