import React, { useEffect } from 'react';

import AppBar from '@material-ui/core/AppBar';
import { Grid } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import FF1Specialist from "../assets/images/240227_ff1_lizenz_siegel_single.svg";

const styles = theme => ({
    logo: {
        cursor: 'pointer',
        height: 60,
        width: "auto",
        maxWidth: 250,
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            height: 60,
            maxWidth: '50%',
        },
    },
    toolbar: {
        backgroundColor: 'white',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    toolbarInner: {
        margin: '0 auto',
        padding: '0 1rem',
        maxWidth: 1280,
    },
    ff1TrustBadge: {
        display: "none",
        position: "absolute",
        top: "0",
        right: "0",
        height: "100%",

        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
    },
    logoWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row'
    },
});

function Header(props) {
    const { classes, history, c2c, isc2c, logoPath } = props;


    useEffect(() => {
        return () => { }
    }, [])

    return (
        <AppBar position={"relative"}>
            <Toolbar className={classes.toolbar}>
                <Grid container justify="space-between" alignItems="center" className={classes.toolbarInner} style={{ height: '100%' }}>
                    <Grid item xs={12} className={classes.logoWrapper}>
                        <HeaderLogo classes={classes} c2c={c2c} history={history} isc2c={isc2c} logoPath={logoPath} />
                    </Grid>
                </Grid>
            </Toolbar>
            <img src={FF1Specialist} className={classes.ff1TrustBadge} alt={'Fair Force One'}/>

        </AppBar>
    )
};

// if c2c present display the name
const HeaderLogo = ({ c2c, history, classes, isc2c, logoPath }, ...props) => {
    if (isc2c) {
        if (c2c['fullname'] !== undefined) {
            return (
                <div>
                    <Typography color={"primary"}
                        variant="h5">
                        <strong>{`${c2c.gender} ${c2c.fullname}`}</strong>
                    </Typography>
                    <Typography color={"primary"}
                        variant="h6">
                        {`empfiehlt fairforce.one`}
                    </Typography>
                </div>
            )
        } else return null
    } else {
        return (
                <img className={classes.logo} src={logoPath} alt="Logo"
                    onClick={() => history.push(props.url)} />
        )
    }
};

export default withRouter(withStyles(styles)(Header));
