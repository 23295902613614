import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Typography } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    successContainer: {
        margin: '50px',
        overflow: 'auto',
    },
    successGreen: {
        color: '#548235',
        marginBottom: '20px'
    }
});

class SignedSuccessful extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid container alignItems="center" justify="center" spacing={5}>
                <Grid item xs={12}>
                    <div className={classes.successGreen}>
                        <Typography align={"center"} variant={"h3"}>- Erfolgsmeldung -</Typography>
                    </div>
                    <Typography variant={"body1"} align={"left"}>
                        Geschafft. Unterschrift 2 von 2 ist erfolgreich im System eingegangen.<br/>
                        <br/>
                        Wie geht es weiter?<br/>
                        1) In den nächsten Minuten wird sich ein Spezialist aus unserem Netzwerk telefonisch bei Ihnen melden.<br/>
                        2) Sobald die zur Regulierung wichtigen Angaben von Ihnen aufgenommen wurden, erhalten Sie binnen 24 Stunden eine qualifizierte Einschätzung zur Schuldverteilung via E-Mail.<br/>
                        3) Zusammen mit den Zahlen aus Ihrem Gutachten oder Kostenvoranschlag haben Sie danach die Basis für den dritten Schritt: Die konkrete Planung der Reparatur oder der Wiederbeschaffung eines adäquaten Fahrzeuges.<br/>
                        <br/>
                        Herzliche Grüße<br/>
                        <br/>
                        Ihr Team fairforce.one
                    </Typography>

                </Grid>
            </Grid>
        );
    }
}

export default withRouter(withStyles(styles)(SignedSuccessful));
