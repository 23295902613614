import React, {useEffect, useState} from 'react';
import {withStyles, Typography, Grid, Button, CircularProgress} from '@material-ui/core';
import axios from 'axios';
import {withRouter} from 'react-router';
import {withSnackbar} from 'notistack';
import SignaturePad from './SignaturePad';
import Download from './Download';
import NewCaseWrapper from "../NewCaseWrapper";
import IntroText from "../CreateCase/IntroText";
import ProcessSigning from "./ProcessSigning";
import InfoTooltip from "../Shared/InfoTooltip";
import {FF1Colors} from "../../themes/global-colors";
import TrustLogos from "../Shared/TrustLogos";

const styles = theme => ({
    signingWrapper: {
        padding: theme.spacing(1),
        minHeight: '50vh'

    },
    loadingCircleContainer: {
        minHeight: '50vh'
    },
    loadingCircle: {
        marginTop: 90,
        [theme.breakpoints.up('md')]: {
            marginTop: 150,
        },
    },
    buttonContainer: {
        marginBottom: 10,
    },
    legalCheckbox: {
        maxWidth: '66%',
        margin: '5px 15px',
        '& span': {
            fontSize: 13,
            textAlign: 'right'
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            margin: '15px',
        },
    },
    summaryImageContainer: {
        overflow: 'auto',
    },
    summaryImage: {
        display: 'block',
        maxWidth: '100%',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            marginTop: -75
        },
    },
    margin: {
        margin: '15px'
    },
    successContainer: {
        minHeight: '50vh',
    },
    successGreen: {
        color: '#548235',
        marginBottom: '20px'
    },
    typeBold: {
        fontWeight: 700,
    },
    divider: {
        width: '100%',
        borderTop: `1px solid ${FF1Colors.lightGray}`,
        borderBottom: 0
    }
});

const SummaryAndSigning = props => {
    const {classes, isc2c, c2cpersonid} = props;
    const [showError, setShowError] = useState(false);
    const [showPoa, setShowPoa] = useState(true);
    const [showCaa, setShowCaa] = useState(false);
    const [showDownload, setShowDownload] = useState(false);

    const [showLoading, setShowLoading] = useState(true);
    const [sending, setSending] = useState(false);

    const [signaturePadOpen, setSignaturePadOpen] = useState(false);
    const [poaSignature, setPoaSignature] = useState('');
    const [caaSignature, setCaaSignature] = useState('');
    const [showPoaSuccess, setShowPoaSuccess] = useState(false);

    const [poaImage, setPoaImage] = useState();
    const [caaImage, setCaaImage] = useState();

    const loadPoaImage = () => {
        setShowError(false);
        const data = JSON.parse(sessionStorage.getItem("case"));
        if (!data) {
            //redirect to case input
            const path = props.baseUrl;
            props.history.push(path);
        }
        axios({
            method: 'post',
            url: '/publicapi/createcase/previewpoapng',
            data: data,
        })
            .then(response => {
                setPoaImage('data:image/png;base64, ' + response.data);
                // setShowLoading(false);
            })
            .catch(error => {
                setShowError(true);
                setShowLoading(false);
            });
    }

    const loadCaaImage = () => {
        setShowError(false);
        const data = JSON.parse(sessionStorage.getItem("case"));
        if (!data) {
            //redirect to case input
            const path = props.baseUrl;
            props.history.push(path);
        }
        axios({
            method: 'post',
            url: '/publicapi/createcase/previewcaapng',
            data: data,
        })
            .then(response => {
                setCaaImage('data:image/png;base64, ' + response.data);
                // setShowLoading(false);
            })
            .catch(error => {
                setShowError(true);
                setShowLoading(false);
            });
    }

    useEffect(() => {
        loadPoaImage();
        loadCaaImage();
    }, []);

    /**
     * Close the damn signature pad when browser orientation changes
     * or we'll never get the sizing issues fixed
     */
    useEffect(() => {
        const closePadOnResize = () => {
            setSignaturePadOpen(false);
        };
        window.screen.orientation.addEventListener("change", closePadOnResize);
        return () => {
            //cleanup work
            window.screen.orientation.removeEventListener('change', closePadOnResize);
        };
    }, []);

    const closePad = () => {
        setSignaturePadOpen(false);
    };

    const openPad = () => {
        setSignaturePadOpen(true);
    };

    const continueSigning = () => {
        setShowPoaSuccess(false);
        setShowCaa(true);
    }

    const signPoa = (signature) => {
        signature && setPoaSignature(signature);
        closePad();
        setShowPoa(false);
        setShowPoaSuccess(true);
    }

    const signCaa = (signature) => {
        signature && setCaaSignature(signature);
        setShowCaa(false);
        toDownload();
    }

    const toDownload = () => {
        setShowDownload(true);
    };

    const send = (comment, confirmC2CInfo) => () => {
        if (!poaSignature || !caaSignature) return;
        setSending(true);
        const signUrl = '/publicapi/createcase/signPoaWithCaa';
        // const noSignUrl = '/publicapi/createcase/sendpoaviamail'; // deprecated

        let data = {
            ...JSON.parse(sessionStorage.getItem("case")),
            additionaltext: comment,
            signature: poaSignature && poaSignature,
            signature2: caaSignature && caaSignature,
        };

        if (isc2c) {
            data.reporterPersonId = c2cpersonid;
            data.sendC2CInfoToAdvertiser = confirmC2CInfo;
        }
        axios({
            method: 'post',
            url: signUrl,
            data: data,
            timeout: 5000,
        })
            .then(response => {
                sessionStorage.setItem("case", null);
                const path = props.baseUrl + "/confirmation";
                props.history.push(path);
                setSending(false);
            })
            .catch(error => {
                // REDIRECT EVENT IF SENDING FAILS
                // TODO: Actually fix mail sending on dev server so the timeout doesn't happen
                sessionStorage.setItem("case", null);
                const path = props.baseUrl + "/confirmation";
                props.history.push(path);
                setSending(false);

                // console.log(error);
                // props.enqueueSnackbar('Fehler beim Versenden der Vollmacht. Bitte kontaktieren Sie unseren Service: 0800 - 30 111 60.', {variant: 'warning'});
                // setSending(false);
            });
    };

    return (

        <React.Fragment>
            <NewCaseWrapper
                greetingPanel={<IntroText/>}
                infoPanel={<ProcessSigning showPoaSuccess={showPoaSuccess} showCaa={showCaa}/>}
            >
                <Grid container className={classes.signingWrapper}>
                    {
                        showLoading &&
                        <Grid container justify='center' className={classes.loadingCircleContainer}>
                            <CircularProgress color='secondary' className={classes.loadingCircle}/>
                        </Grid>
                    }
                    {
                        poaImage && showPoa &&
                        < React.Fragment>
                            <Grid container justify='center' className={classes.summaryImageContainer}>
                                <Grid item className={classes.margin}>
                                    <Typography align={"center"} className={classes.typeBold}>Ihre faire
                                        Regulierung beauftragen</Typography>
                                    <Typography align={"center"}>Unterschrift 1 von 2</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify='center' className={classes.summaryImageContainer}>
                                <Grid item className={classes.summaryImageItem}>
                                    <img src={poaImage} className={classes.summaryImage} onLoad={() => {
                                        setShowLoading(false)
                                    }} alt={'summary'}/>
                                </Grid>
                            </Grid>
                            <Grid container justify='center' className={classes.buttonContainer}>
                                <Grid container alignItems='center' style={{marginBottom: 20}}>
                                    <InfoTooltip isOpen={true} title='Leere Felder?'
                                                 copy='Die Informationen in den leeren Feldern sind für die Beauftragung nicht notwendig. Sie können diese später nachreichen.'/>
                                </Grid>

                                <Grid container alignItems='center' justify='flex-end'>
                                    <Button onClick={openPad}>Jetzt unterschreiben</Button>
                                </Grid>
                            </Grid>
                            {
                                signaturePadOpen &&
                                <SignaturePad baseUrl={props.baseUrl}
                                              open={signaturePadOpen}
                                              close={closePad}
                                              sign={signPoa}
                                              buttonLabel='Weiter zum nächsten Dokument'
                                />
                            }
                        </React.Fragment>
                    }

                    {
                        showPoaSuccess &&
                        < React.Fragment>
                            <Grid container justify='center' alignItems='center' className={classes.successContainer}>
                                <Grid item xs={12}>
                                    <Typography paragraph align={"center"} variant="h6"
                                                className={classes.typeBold}>Erfolgsmeldung</Typography>
                                    <Typography paragraph align={"center"} variant="body2">Unterschrift 1 von 2 ist
                                        erfolgreich im System
                                        eingegangen.</Typography>
                                    <Grid container justify='center' className={classes.buttonContainer}>
                                        <Button onClick={continueSigning}>Weiter zur zweiten Unterschrift</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }

                    {
                        caaImage && showCaa &&
                        < React.Fragment>
                            <Grid container justify='center' className={classes.summaryImageContainer}>
                                <Grid item className={classes.margin}>
                                    <Typography align={"center"} className={classes.typeBold}>Ihre faire
                                        Regulierung beauftragen</Typography>
                                    <Typography align={"center"}>Unterschrift 2 von 2</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify='center' className={classes.summaryImageContainer}>
                                <Grid item className={classes.summaryImageItem}>
                                    <img src={caaImage} className={classes.summaryImage} onLoad={() => {
                                        setShowLoading(false)
                                    }} alt={'summary'}/>
                                </Grid>
                            </Grid>
                            <Grid container justify='center' className={classes.buttonContainer}>
                                <Grid container alignItems='center' style={{marginBottom: 20}}>
                                    <InfoTooltip isOpen={true} title='Leere Felder?'
                                                 copy='Die Informationen in den leeren Feldern sind für die Beauftragung nicht notwendig. Sie können diese später nachreichen.'/>
                                </Grid>

                                <Grid container alignItems='center' justify='flex-end'>
                                    <Button onClick={openPad}> Jetzt unterschreiben</Button>
                                </Grid>
                            </Grid>
                            {
                                signaturePadOpen &&
                                <SignaturePad baseUrl={props.baseUrl}
                                              open={signaturePadOpen}
                                              close={closePad}
                                              sign={signCaa}
                                              buttonLabel='Weiter'
                                />
                            }
                        </React.Fragment>
                    }
                    {
                        showDownload &&
                        <Download send={send} sending={sending} isc2c={isc2c} c2cpersonid={c2cpersonid}/>
                    }
                    {
                        showError &&
                        <React.Fragment>
                            <Grid container style={{padding: 10}}>
                                <Grid item style={{padding: 10, borderRadius: 10, background: 'orange'}}>
                                    <Typography style={{fontWeight: 600,}}>Fehler beim Anzeigen der Vollmacht. Bitte
                                        kontaktieren Sie unseren Service: 0800 - 30 111 60.</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify='center'>
                                <Grid item>
                                    <Button onClick={() => {
                                        props.history.push(props.baseUrl)
                                    }}>Back</Button>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }

                    <hr className={classes.divider}/>
                    <TrustLogos/>
                </Grid>
            </NewCaseWrapper>
        </React.Fragment>
    )
};

export default withSnackbar(withRouter(withStyles(styles)(SummaryAndSigning)));
