import { Button, Grid, Hidden, Icon, Menu, MenuItem } from '@material-ui/core';

import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {FF1Colors} from "../themes/global-colors";

const styles = theme => ({
    appBar: {
        background: "white",
        color: FF1Colors.mediumGray,
    },
    companyLogo: {
        minHeight: 40,
        maxHeight: 50,
        cursor: 'pointer',
        marginRight: theme.spacing(1),
        maxWidth: "50%",
        objectFit: "contain",
    },
    title: {
        overflow: 'hidden',
        textAlign: 'left',
        height: '24px',
        cursor: 'pointer'
    },
    appBarButton: {
        color: FF1Colors.darkGray,
        margin: '0 15px',
        fontSize: 10,
    },
    appBarButtonBurger: {
        //color: FF1Colors.lightestGray,
        margin: '0 15px',
        fontSize: 10,
    },
    menuIcon: {
        margin: '0 10px',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
});

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mobileMoreAnchorEl: null,
            showCaseButtons: false,
            url: this.props.url
        }
    }

    componentWillReceiveProps(props) {
        if (JSON.parse(sessionStorage.getItem('token'))) {
            this.setState({
                showCaseButtons: true
            });
        }
        this.setState({ url: props.url });
    }

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    logout = () => {
        sessionStorage.removeItem('caseDetails');
        sessionStorage.removeItem('documents');
        sessionStorage.removeItem('doctypes');
        sessionStorage.removeItem('token');
        this.setState({
            showCaseButtons: false
        });
        this.props.history.push(this.props.url + "/login");
    }

    render() {
        const { classes, history } = this.props;
        const { mobileMoreAnchorEl, showCaseButtons } = this.state;
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        // MOBILE DROPDOWN BUTTONS
        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                {
                    showCaseButtons &&
                    <Grid justify="flex-end">
                        <MenuItem onClick={() => {
                            this.handleMobileMenuClose();
                            history.push(this.props.url + "/casedetails");
                        }}><Icon className={classes.menuIcon}>assignment</Icon>Falldetails</MenuItem>
                        <MenuItem onClick={() => {
                            this.handleMobileMenuClose();
                            history.push(this.props.url + "/documents")
                        }
                        }><Icon className={classes.menuIcon}>cloud_upload</Icon>Dokumente senden</MenuItem>
                        <MenuItem onClick={() => {
                            this.handleMobileMenuClose();
                            history.push(this.props.url + "/documents");
                        }}><Icon className={classes.menuIcon}>filter</Icon>Dokumente ansehen</MenuItem>
                        <MenuItem onClick={() => {
                            this.handleMobileMenuClose();
                            history.push(this.props.url + "/kontakt");
                        }}><Icon className={classes.menuIcon}>email</Icon>Kontakt</MenuItem>
                        <MenuItem onClick={() => {
                            this.handleMobileMenuClose();
                            history.push(this.props.url + "/recommendations");
                        }}><Icon className={classes.menuIcon}>euro_symbol</Icon><strong>Jetzt Empfehlen und 15€
                            sichern</strong></MenuItem>
                    </Grid>
                }
                {
                    showCaseButtons ?
                        <MenuItem onClick={() => {
                            this.handleMobileMenuClose();
                            this.logout();
                        }}>
                            <Icon className={classes.menuIcon}>power_settings_new</Icon>Abmelden
                        </MenuItem>
                        :
                        <MenuItem onClick={() => {
                            history.push(this.props.url + "/login")
                        }}>
                            <Icon className={classes.menuIcon}>power_settings_new</Icon>Login
                        </MenuItem>
                }
            </Menu>
        );

        return (
            <div>
                <AppBar className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <img className={classes.companyLogo} src={this.props.logoPath} alt="Logo"
                            onClick={() => history.push(this.props.url + "/login")} />
                        <Grid container>
                            <Grid item>
                                <Hidden smDown>
                                    <Typography className={classes.title} variant="subtitle1" nowrap="true"
                                        color="inherit" onClick={() => history.push(this.props.url + "/login")}>
                                        KIS
                                    </Typography>
                                </Hidden>
                            </Grid>
                        </Grid>
                        {/* DESKTOP MENU BUTTONS */}
                        <div className={classes.sectionDesktop}>
                            {
                                showCaseButtons &&
                                <React.Fragment>
                                    <Button variant='text' className={classes.appBarButton} onClick={() => {
                                        this.handleMobileMenuClose();
                                        history.push(this.props.url + "/casedetails");
                                    }}>
                                        <Icon className={classes.menuIcon}>assignment</Icon>
                                        Falldetails
                                    </Button>
                                    <Button variant='text' className={classes.appBarButton} onClick={() => {
                                        this.handleMobileMenuClose();
                                        history.push(this.props.url + "/documents");
                                    }}>
                                        <Icon className={classes.menuIcon}>cloud_upload</Icon>
                                        Dokumente senden
                                    </Button>
                                    <Button variant='text' className={classes.appBarButton} onClick={() => {
                                        this.handleMobileMenuClose();
                                        history.push(this.props.url + "/documents");
                                    }}>
                                        <Icon className={classes.menuIcon}>filter</Icon>
                                        Dokumente ansehen
                                    </Button>
                                    <Button variant='text' className={classes.appBarButton} onClick={() => {
                                        this.handleMobileMenuClose();
                                        history.push(this.props.url + "/kontakt");
                                    }}>
                                        <Icon className={classes.menuIcon}>email</Icon>
                                        Kontakt
                                    </Button>
                                    <Button variant='text' className={classes.appBarButton} onClick={() => {
                                        this.handleMobileMenuClose();
                                        history.push(this.props.url + "/recommendations");
                                    }}>
                                        <Icon className={classes.menuIcon}>euro_symbol</Icon>
                                        <strong>Jetzt Empfehlen und 15€ sichern</strong>
                                    </Button>
                                </React.Fragment>

                            }
                            {
                                showCaseButtons ?
                                    <Button variant='text' className={classes.appBarButton} onClick={() => {
                                        this.handleMobileMenuClose();
                                        this.logout();
                                    }}>
                                        <Icon className={classes.menuIcon}>power_settings_new</Icon>Abmelden
                                    </Button>
                                    :
                                    null
                            }
                        </div>
                        {/* MOBILE MORE ICON */}
                        <div className={classes.sectionMobile}>
                            <Button variant='contained' color='secondary' className={classes.appBarButtonBurger}
                                onClick={this.handleMobileMenuOpen}><Icon>menu</Icon></Button>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
            </div>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Header));
