import React from 'react';
import {withStyles, Grid, Typography, List, ListItem} from '@material-ui/core';
import IntroText from '../CreateCase/IntroText';
import NewCaseWrapper from "../NewCaseWrapper";
import FF1Logo from "../../assets/images/ff1_logo.svg"
import {FF1Colors} from "../../themes/global-colors";


const styles = theme => ({
    confirmationWrapper: {
        padding: theme.spacing(2)
    },
    typeBold: {
        fontWeight: 700,
    },
    counterParent: {
        counterReset: 'list',
        paddingLeft: '2em',
    },
    counter: {
        counterIncrement: 'list',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'flex-start',

        "&::before": {
            content: `counter(list)`,
            position: 'absolute',
            top: '8px',
            left: '-15px',
            width: '20px',
            height: '20px',
            border: `1px solid ${FF1Colors.darkGray}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
        }
    },
    confirmationLogo: {
        padding: 15,
        maxHeight: 75,
        width: 'auto',
    },
    divider: {
        width: '100%',
        borderTop: `1px solid ${FF1Colors.lightGray}`,
        borderBottom: 0
    }
});

const Confirmation = props => {
    const {classes, logoPath} = props;
    return (
        <React.Fragment>
            <NewCaseWrapper
                greetingPanel={<IntroText/>}
                infoPanel={<div></div>}
            >
                <Grid container justify='center' className={classes.confirmationWrapper}>
                    <Grid item xs={12} style={{marginBottom: 20}}>
                        <Typography align={"center"} className={classes.typeBold}>Ihre faire Regulierung wurde
                            beauftragt.</Typography>
                        <Typography align={"center"}>Unterschrift 2 von 2 ist erfolgreich bei uns
                            eingegangen.</Typography>
                        <hr className={classes.divider}/>
                    </Grid>

                    <Grid item xs={12} md={12} style={{marginBottom: 20}}>
                        <Typography paragraph className={[classes.type, classes.typeBold].join(' ')} align='center'>Wie
                            geht es jetzt weiter?</Typography>
                        <List className={classes.counterParent}>
                            <ListItem className={classes.counter}>
                              Ein Regulierungs-Spezialist unseres Partners fairforce.one ruft Sie in Kürze an.
                            </ListItem>
                            <ListItem className={classes.counter}>
                                Reparatur, Gutachter, Mietwagen – wir besprechen Ihre individuellen Anforderungen telefonisch mit Ihnen.
                            </ListItem>
                            <ListItem className={classes.counter}>
                                Ihr persönliches Kunden-Informations-System (KIS) ist bereits freigeschalten. Die Zugangsdaten haben Sie per E-Mail erhalten.
                            </ListItem>
                            <ListItem className={classes.counter}>
                                <strong>Im KIS können Sie bereits jetzt wichtige Dokumente hochladen (Bspw. Fotos vom Unfallort).</strong>
                                Je früher wir diese Daten haben, desto schneller können wir Ihre Ansprüche zur Auszahlung bringen.
                            </ListItem>
                        </List>
                    </Grid>

                    <Grid item xs={12} md={4} style={{marginBottom: 20}}>

                    </Grid>


                    <Grid item xs={12} style={{marginBottom: 20}}>
                        <Typography align={"center"} className={classes.typeBold}>Danke für Ihr Vertrauen und bis gleich,</Typography>

                        <Grid container justify='center' alignItems='center'>
                            <img className={classes.confirmationLogo} src={logoPath} alt="Logo" />
                            <img className={classes.confirmationLogo} src={FF1Logo} alt="Logo fairforce.one" />
                        </Grid>
                    </Grid>
                </Grid>
            </NewCaseWrapper>
        </React.Fragment>
    )
};

export default withStyles(styles)(Confirmation);