import React, {useState, useEffect} from 'react';
import {withStyles, Grid, InputAdornment, Typography, TextField} from '@material-ui/core';
import {MailRounded, PhoneRounded} from '@material-ui/icons';

const styles = theme => ({
    adornment: {
        margin: 0,
        paddingRight: 8,
        height: '100%',
        backgroundColor: 'transparent',
        [theme.breakpoints.up('md')]: {
            backgroundColor: 'transparent',
        },
        color: 'grey',
    },
    nextStepText: {
        fontSize: 14,
        fontWeight: 600,
        marginBottom: 10
    },
    hasError: {
        animation: "$shake 1s",
        '& fieldset': {
            borderColor: theme.palette.error.main + '!important',
        },
        '& input': {
            borderColor: theme.palette.error.main,
        }
    },
    "@keyframes shake": {
        "0%": {transform: "translateX(0)"},
        "15%": {transform: "translateX(-4px)"},
        "30%": {transform: "translateX(6px)"},
        "45%": {transform: "translateX(-4px)"},
        "60%": {transform: "translateX(6px)"},
        "100%": {transform: "translateX(0)"}
    }
});

const CustomerData = props => {
    const {classes, showErrors} = props;

    // Component States
    const [state, setState] = useState({
        forename: props.state.forename || '',
        lastname: props.state.lastname || '',
        tel: props.state.tel || '',
        email: props.state.email || '',
        zip: props.state.zip || '',
    });

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    useEffect(() => {
        props.stateChange(state);
    }, [state.forename, state.lastname, state.tel, state.email, state.zip]);

    const validEmail = () => {
        let email = props.state.email

        // console.log(email.length > 0 && /(.+)@(.+){2,}\.(.+){2,}/.test(email));

        return email.length > 0 &&
            /(.+)@(.+){2,}\.(.+){2,}/.test(email)
    }

    const validateForm = () => props.validationHandler(() => {
        return props.state.forename.length > 0 &&
            props.state.lastname.length > 0 &&
            props.state.tel.length > 0 &&
            props.state.zip.length > 0 &&
            validEmail()
    });
    validateForm()

    return (

        <Grid container direction='column' justify='space-between'>
            <Grid item>
                <Grid container justify='center'>
                    <Grid item xs={12}>
                        <Typography variant='body1' className={classes.nextStepText}>Im nächsten Schritt bereiten
                            wir die notwendige Vollmacht für Sie vor. Dafür brauchen wir Ihre Daten.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    className={showErrors && !state.forename ? classes.hasError : "noClass"}
                                    style={{marginBottom: 15}}
                                    variant="outlined"
                                    label="Vorname *"
                                    name="forename"
                                    fullWidth
                                    value={state.forename}
                                    onChange={handleChange}
                                    validators={['required']}
                                    inputProps={{
                                        autoComplete: 'given-name'
                                    }}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    className={showErrors && !state.lastname ? classes.hasError : "noClass"}
                                    style={{marginBottom: 15}}
                                    variant="outlined"
                                    label="Nachname *"
                                    name='lastname'
                                    fullWidth
                                    value={state.lastname}
                                    onChange={handleChange}
                                    validators={['required']}
                                    inputProps={{
                                        autoComplete: 'family-name'
                                    }}
                                />
                            </Grid>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    className={showErrors && !state.zip ? classes.hasError : "noClass"}
                                    style={{marginBottom: 15}}
                                    variant="outlined"
                                    label="Postleitzahl *"
                                    name='zip'
                                    fullWidth
                                    value={state.zip}
                                    onChange={handleChange}
                                    validators={['required']}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        autoComplete: 'postal-code'
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item sm={5} xs={12}>
                                <TextField
                                    className={showErrors && !state.tel ? classes.hasError : "noClass"}
                                    style={{marginBottom: 20}}
                                    variant="outlined"
                                    label="Telefonnummer *"
                                    name='tel'
                                    value={state.tel}
                                    fullWidth
                                    onChange={handleChange}
                                    validators={['required']}
                                    InputProps={{
                                        inputMode: 'tel',
                                        autoComplete: 'tel',
                                        startAdornment: <InputAdornment className={classes.adornment}
                                                                        position="start"> <PhoneRounded/>
                                        </InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item sm={7} xs={12}>
                                <TextField
                                    className={showErrors && !validEmail() ? classes.hasError : "noClass"}
                                    style={{marginBottom: 20}}
                                    label="E-Mail *"
                                    name="email"
                                    type="email"
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        inputMode: 'email',
                                        autoComplete: 'email',
                                        startAdornment: <InputAdornment className={classes.adornment}
                                                                        position="start"> <MailRounded/>
                                        </InputAdornment>,
                                    }}
                                    value={state.email}
                                    onChange={handleChange}
                                    validators={['required', 'isEmail']}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )
};

export default withStyles(styles)(CustomerData);