import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        [`& fieldset`]: {
          borderRadius: 0,
        },
      }
    },
    MuiButton: {
      containedSecondary: {
        color: 'white',
        //borderRadius: 10,
        boxShadow: 'none',
        textTransform: 'none'
      }
    }
  },

  palette: {
    primary: {
      main: '#333333',
      light: '#999999',
      lighter: '#cccccc',
      lightest: '#e0e0e0',
      contrastText: '#f0f0f0',
      blend: '#333333a6',
    },
    secondary: {
      main: '#eaeaea',
    },
    error: {
      main: '#d0021b',
    },
    grey: {
      light: '#F0EFEE',
      main: '#EEEFED',
    },
    beige: {
      main: '#EBDFB7',
    },
    contrastThreshold: 3,
    tonalOffset: 0.3,
  },

  typography: {
    fontSize: 16,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Raleway"',
      'sans-serif',
    ].join(','),
  },

  props: {
    // Name of the component ⚛️
    MuiButton: {
      variant: "contained",
      color: "secondary"
    },
    MuiFormControl: {
      margin: "dense"
    }
  }
});

theme = responsiveFontSizes(theme);

export default theme;
