import axios from 'axios';
import {createMuiTheme} from '@material-ui/core/styles';
import frTheme from './fr-theme';
import globalTheme from "./global-theme";

export function getThemeForKis(uuid) {
    return getTheme(uuid, null, true);
}
export function getThemeForSignVm(uuid) {
    return getTheme(uuid);
}

function getTheme(uuid, sourceId, isKis = false, isEfa = false) {
    return axios({
        method: 'get',
        url: uuid ? '/publicapi/kisTheme' : '/publicapi/theme',
        params: {uuid, sourceId}
    }).then(response => {

        if (!response.data.id || (isKis && !response.data.useThemeForKis) || (isEfa && !response.data.useThemeForEva)) {
            return getFrTheme(response.clientName);
        } else {

            let theme = createThemeWithSufficientContrast(response.data.primaryColor, response.data.secondaryColor);

            return {
                clientName: response.data.clientName,
                theme,
                logoPath: response.data.logoPath,
                faviconPath: response.data.faviconPath
            };
        }
    }).catch(error => {
        console.error(error);

        return getFrTheme();
    });
}

export function getThemeForEfa(sourceId) {
    return getCompanyInfo(sourceId);
}

/**
 * Get company info, including theme, branches, employees, logos
 *
 * @param sourceId
 * @returns {Promise<AxiosResponse<any> | {clientName: *|string, logoPath: string, faviconPath: string, theme: Theme, logov2Path: string}>}
 */
export function getCompanyInfo(sourceId) {
    sourceId = parseInt(sourceId);

    return axios({
        method: 'get',
        url: '/publicapi/getCompany/' + sourceId,
    }).then(response => {
        if (!response.data.id) {
            return getFrTheme(response.clientName);
        } else {
            // We could check contrast like this:
            // https://www.npmjs.com/package/color-contrast-calc#installation

            const branches = response.data.branches;

            let badgeData = {};

            let currentBranch = branches.find(obj => {
                return obj.id === sourceId;
            });

            // not branch
            if (!currentBranch) {

                branches.forEach((branch) => {
                    branch.employees.every((employee) => {
                        if (employee.id === sourceId) {
                            currentBranch = branch;

                            badgeData.employee = {
                                name: employee.name,
                            };
                            badgeData.branch = {
                                name: branch.name,
                            };

                            return false;
                        }

                        return true;
                    })
                })

                if (!currentBranch) {
                    return getFrTheme(response.clientName);
                }
            }

            let defaultData;

            if (currentBranch.theme) {

                let theme = createThemeWithSufficientContrast(currentBranch.theme.primaryColor, currentBranch.theme.secondaryColor);

                defaultData = {
                    theme,
                    logoPath: currentBranch.theme.logoPath,
                    faviconPath: currentBranch.theme.faviconPath,
                }
            } else {
                defaultData = getFrTheme(response.clientName);
            }

            return {
                ...defaultData,
                clientName: currentBranch.name || false,
                employees: currentBranch.employees || false,
                branches: branches,
                badgeData: badgeData,
            };
        }
    }).catch(error => {
        console.error(error);

        return getFrTheme();
    });

}

function createThemeWithSufficientContrast(primaryColor, secondaryColor) {

    const shadeColor = (color, percent) => {

        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        R = Math.round(R)
        G = Math.round(G)
        B = Math.round(B)

        var RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
        var GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
        var BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

        return "#" + RR + GG + BB;
    }

    /**
     * Measure the contrast of two hex colors
     *
     * @param {string} hexA in format "#ffffff" or "ffffff"
     * @param {string} hexB
     * @returns {number} between 0 and 1, > .22 or .33 is recommended
     */
    const contrastRatio = (hexA, hexB) => {
        const roughLuminanceRatio = (rgb) => {
            // 1. roughly convert sRGB colorspace to linear RGB  (optional). / 255 for ratios
            const [r, g, b] = rgb.map(channel => (channel / 255) ** 2.218)

            // 2. rgb coefficients for adjusting channels to human eye sensitivity to different light wavelengths (kinda subjective)
            return r * 0.2126 + g * 0.7152 + b * 0.0722
        }

        const hexToRGB = (c) => {
            c = c.replace("#", "");

            return [0, 0, 0].map((_, i) => {
                return parseInt(c.substr(i * 2, 2), 16)
            })
        }

        const luminanceRatios = [hexA, hexB].map(hexToRGB).map(roughLuminanceRatio)
        return ((Math.max(...luminanceRatios) + 0.05) / (Math.min(...luminanceRatios) + 0.05)) / 21
    }

    let themeButtonTextColor = "#ffffff";
    let themeColor = primaryColor;
    let themeColorSecondary = secondaryColor;


    let buttonContrastRatio = contrastRatio(themeColor, themeButtonTextColor);
    let buttonContrastRatioAlt = contrastRatio(themeColorSecondary, themeButtonTextColor);

    console.log({
        primary: buttonContrastRatio,
        secondary: buttonContrastRatioAlt,
    });

    // First color sucks, is probably a grey/white
    if (buttonContrastRatio < 0.1) {
        themeColor = themeColorSecondary;

        // second color also sucks, change button text color
        if (buttonContrastRatioAlt < 0.1) {
            themeButtonTextColor = "#333";
        }
    }

    let buttonHoverColor = shadeColor(themeColor, -10);

    return createMuiTheme({
        ...globalTheme,

        overrides: {
            MuiButton: {
                containedSecondary: {
                    color: themeButtonTextColor,

                    '&:hover': {
                        backgroundColor: buttonHoverColor,
                    },
                }
            }
        },

        palette: {
            ...globalTheme.palette,
            primary: {
                ...globalTheme.palette.primary,
                main: themeColor,

            },
            secondary: {
                ...globalTheme.palette.secondary,
                main: themeColor,
            },
            accent: {
                main: themeColorSecondary,
            },
        },
    });
}

function getFrTheme(clientName) {
    return {
        clientName: clientName ? clientName : 'faire-Regulierung',
        theme: frTheme,
        logoPath: './assets/themes/fr/ff1_logo.svg',
        logov2Path: './assets/themes/fr/ff1_logo.svg',
        faviconPath: './assets/themes/fr/favicon.ico',
    };
}
