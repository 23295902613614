import {
    Grid,
    Typography,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    collapseBox: {
        padding: theme.spacing(2),
        [theme.breakpoints.only('xs')]: {
            padding: 5,
        },
        cursor: 'pointer',
        '&:hover': {
            background: `${fade(theme.palette.secondary.main, 0.3)} !important`,
        }
    },
    collapseContent: {
        padding: theme.spacing(2),
        [theme.breakpoints.only('xs')]: {
            padding: 5,
        },
    },
});

class KisCollapseLog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            in: false,
        }
    }

    handleClick = () => {
        this.setState({
            in: !this.state.in
        });
    }

    render() {
        const { classes } = this.props;
        let background = 'white';
        if (this.props.i % 2 === 0) {
            background = '#e8e9e6';
        }
        return (
            <React.Fragment key={this.props.i}>
                <div className={classes.collapseBox} style={{ background: background }} onClick={this.handleClick}>
                    <Grid container>
                        <Grid item >
                            <Typography >{this.props.data.c !== '' ? (this.state.in ? <ExpandLess /> : <ExpandMore />) : null}</Typography>
                        </Grid>
                        <Grid item >
                            <Typography >{this.props.data.d} {'- ' + this.props.data.t}</Typography>
                        </Grid>
                    </Grid>
                    <Typography><b style={{ fontWeight: 600 }}>{this.props.data.s}</b></Typography>
                    {
                        !this.state.in &&
                        <Typography noWrap>{this.props.data.c}</Typography>
                    }
                    {
                        this.state.in &&
                        <Typography >{this.props.data.c}</Typography>
                    }
                </div>
            </React.Fragment>
        );
    }
}


export default withRouter(withStyles(styles)(KisCollapseLog));



