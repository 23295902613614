import React from 'react';
import {Grid, Typography, withStyles, ButtonBase} from "@material-ui/core";
import {FF1Colors} from "../../themes/global-colors";

const styles = theme => ({
    sequenceContainer: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    stepsWrapper: {
        gap: '2px'
    },
    step: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
        flexGrow: 1,
        border: `1px solid ${FF1Colors.lightestGray}`,
        color: FF1Colors.mediumGray,
        padding: 3,
        height: 30,
        "&:last-child": {
            "& $arrow": {
                display: 'none'
                
            }
        }
    },
    stepActive: {
        background: FF1Colors.lightestGray,
        color: FF1Colors.darkGray,
        "& $arrow": {
            background: FF1Colors.lightestGray,
            borderColor: 'white',
        }
    },
    arrow: {
        zIndex: 1,
        border: `1px solid ${FF1Colors.lightestGray}`,
        borderLeft: 0,
        borderBottom: 0,
        width: 22,
        height: 22,
        position: 'absolute',
        right: -11,
        transform: 'rotate(45deg)',
        background: 'white',
    },
    stepTitle: {
        zIndex: 1,
        [theme.breakpoints.down('md')]: {
            fontSize: 10,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    }
});


const FormSequence = props => {
    const {classes} = props;

    const handleSequenceNavigation = value => () => {
        if (props.index > value) {
            props.setIndex(value);
        }
    };

    if (props.index === 0) {
        return "";
    }

    return (
        <Grid container className={classes.sequenceContainer}>
            <Grid item xs={12}>
                <Typography paragraph variant='h5' align='center'>{props.progress[props.index].titleHeader}</Typography>
                <Grid container justify='center' align='center' className={classes.stepsWrapper}>
                    {props.progress.map((a, index) => {
                        let cssClasses = classes.step;
                        if (props.index >= index) {
                            cssClasses += ` ${classes.stepActive}`;
                        }

                        return (
                            <Grid key={index} item className={cssClasses}>
                                <span className={classes.arrow}></span>
                                <ButtonBase onClick={handleSequenceNavigation(index)} className={classes.stepTitle}>{a.title}</ButtonBase>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
};

export default withStyles(styles)(FormSequence);