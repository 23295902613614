import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Typography } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    successContainer: {
        margin: '50px',
        overflow: 'auto',
    },
    successGreen: {
        color: '#548235',
        marginBottom: '20px'
    }
});

class SignedSuccessful extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid container alignItems="center" justify="center" spacing={5}>
                <Grid item xs={12}>
                    <div className={classes.successGreen}>
                        <Typography align={"center"} variant={"h3"}>- Erfolgsmeldung -</Typography>
                    </div>
                    <Typography variant={"body1"} align={"left"}>
                        Geschafft. Alle erforderlichen Unterschriften sind in das System eingegangen.<br/>
                        <br/>
                        Vielen Dank. Wir werden uns in Kürze bei Ihnen melden.<br/>
                        <br/>
                        Herzliche Grüße<br/>
                        <br/>
                        Ihr Team fairforce.one
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(withStyles(styles)(SignedSuccessful));
