import React from 'react';
import { withStyles, Grid, Typography } from "@material-ui/core";

const styles = theme => ({
  type: {
    color: 'black',
    [theme.breakpoints.up('md')]: {
      color: 'white',
    },
  },
});

const IntroText = ({ classes, c2c }) => {
  return (
    <Grid container justify='center'>
      <Grid item>
        <Typography paragraph className={classes.type} variant='h4' align='center'>Autounfall?</Typography>
        <Typography paragraph className={classes.type} variant='h6' align='center'>Mir haben die sehr geholfen. Einfach hier melden!</Typography>
      </Grid>
    </Grid>
  )
};

export default withStyles(styles)(IntroText);