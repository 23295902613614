import React from 'react';
import { withStyles, Grid, Typography } from "@material-ui/core";

const styles = theme => ({
  type: {
    color: 'white',
    textShadow: '1px 1px 10px rgba(0,0,0,0.3)',
    fontSize: '1rem',

    [theme.breakpoints.up('md')]: {
      color: 'white',
    },
  },
  typeBold: {
    fontWeight: 700,
    fontSize: '2.2rem',
  }
});

const IntroText = ({ classes }) => {
  return (
    <Grid container justify='flex-start'>
      <Grid item>
        <Typography paragraph className={[classes.type, classes.typeBold].join(' ')} variant='h3' align='left'>Autounfall?</Typography>
        <Typography paragraph className={classes.type} variant='h6' align='left'>Als Ihr vertrauter Partner stehen wir auch jetzt an Ihrer Seite.
          Mit wenigen Klicks zum top bewerteten Unfallservice.
        </Typography>
      </Grid>
    </Grid>
  )
};

export default withStyles(styles)(IntroText);