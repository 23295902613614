import React from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid, Button, Icon, Typography
} from '@material-ui/core';
import UploadListItem from './UploadListItem';

const styles = theme => ({
    searchableSelect: {
        width: '90%',
        backgroundColor: 'white',
        paddingLeft: '3px'
    },
})

class Uploader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            apiHost: '',
            token: '',

            uploadDisabled: true,

            files: [],
            doctypesSelected: [],

            progress: [],
            totalFinished: 0,
        }
        this.fileInput = React.createRef();
    }

    handleChange = () => {
        this.activateButton();
        this.setState({
            files: this.fileInput.files
        }, () => {
            const doctypesSelected = [];
            Array.from(this.state.files).forEach((file, i) => {
                doctypesSelected.push(27);
            });
            this.setState({
                doctypesSelected: doctypesSelected
            });
        });
    }

    activateButton = () => {
        if (this.fileInput.files.length !== 0) {
            this.setState({
                uploadDisabled: false,
            });
        }
    }

    uploadFiles = () => {
        Array.from(this.state.files).forEach((file, i) => {
            this.handleUploadClick(file, this.state.doctypesSelected[i], i);
        })
    }

    trackUploadProgress = (event, i) => {
        let progress = this.state.progress;
        let inPercent = 100 * event.loaded / event.total;
        progress.push({
            id: i,
            progress: inPercent
        });
        this.setState({
            progress: progress,
        });
    }

    handleUploadClick = (file, doctype, i) => {
        const formData = new FormData();
        formData.append('files', file);
        axios({
            url: `/kisapi/uploaddocument/${doctype}`,
            method: 'post',
            data: formData,
            headers: {
                Authorization: JSON.parse(sessionStorage.getItem('token'))
            },
            onUploadProgress: (event) => this.trackUploadProgress(event, i)
        })
            .then(() => {
                let totalFinished = this.state.totalFinished + 1;
                this.setState({
                    totalFinished: totalFinished,
                }, () => {
                    if (this.state.totalFinished === Array.from(this.state.files).length) {
                        this.setState({
                            files: [],
                            totalFinished: 0,
                            progress: [],
                        });
                        this.fileInput.value = null;
                        this.activateButton();
                        this.props.uploaded();
                    }
                });
            })
            .catch((error) => {
            });
    }

    doctypeChange = (doctype, index) => {
        let doctypesSelected = this.state.doctypesSelected;
        doctypesSelected.splice(index, 1, doctype);
    }

    render() {

        const { classes } = this.props

        return (
            <React.Fragment>
                <Grid container alignItems="stretch" spacing={1}>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            component="label"
                        >
                            <input
                                ref={(ref) => this.fileInput = ref}
                                id="file"
                                type="file"
                                onChange={this.handleChange}
                                multiple
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            Array.from(this.state.files).map((file, i) => {
                                return <UploadListItem progress={this.state.progress} doctypeChange={this.doctypeChange} key={i} file={file} index={i} />
                            })
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth disabled={this.state.uploadDisabled} color="secondary" size='large' variant="contained" raised='true' component="span" className={classes.button} onClick={this.uploadFiles}>
                            <Icon>cloud_upload</Icon> <Typography style={{ marginRight: '5px' }}>&nbsp;Absenden</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Uploader);