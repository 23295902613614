import { Redirect, Route, Switch } from 'react-router-dom';

import NewCaseRouter from './NewCaseRouter';
import React from 'react';

const NewCaseApp = (props) => {
  const { match } = props;

  const redirectUrl = props.match.url + "/280";

  return (
    <Switch>
      <Route path={`${match.path}/:sourceid`} component={NewCaseRouter} />
      <Redirect to={redirectUrl} />
    </Switch>
  );
}

export default NewCaseApp;
