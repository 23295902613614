import { Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import Footer from '../footer/Footer';
import Grid from '@material-ui/core/Grid';
import Header from '../header/SigVmHeader';
import { Helmet } from "react-helmet";
import Impressum from '../footer/Impressum'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Privacy from '../footer/Privacy'
import React from 'react';
import SignedSuccessful from './SignedSuccessful';
import Signing from './Signing';
import { getThemeForSignVm } from './../themes/theme.service';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        paddingTop: theme.spacing(5),
        paddingBottom: '25px',
    },

    outergrid: {
        margin: theme.spacing(2),
        maxWidth: '1000px'
    }
});

class SignVmRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: this.props.match.params.clientid,
            clientName: null,
            theme: null,
            logoPath: null,
            faviconPath: null
        }
    }

    componentDidMount() {
        this.getTheme();
    }

    getTheme() {
        getThemeForSignVm(this.state.uuid).then(data => {
            this.setState({
                clientName: data.clientName,
                theme: data.theme,
                logoPath: data.logoPath,
                faviconPath: data.faviconPath
            })
        });
    }

    render() {
        const { classes, match } = this.props;
        var redirectUrl = match.url

        if (this.state.theme) {
            return (
                <MuiThemeProvider theme={this.state.theme}>
                    <SnackbarProvider
                        maxSnack={3} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    <Helmet>
                        <title>Online Unterschrift</title>
                        <link rel="icon" type="image/png" href={this.state.faviconPath} sizes="16x16" />
                        <link rel="shortcut icon" href={this.state.faviconPath}></link>
                    </Helmet>
                    <Header url={redirectUrl} title="Ihre Online Unterschrift bei" clientName={this.state.clientName} logoPath={this.state.logoPath} />
                    <div className={classes.root}>
                        <Grid container alignItems="center" justify="center" spacing={0} >
                            <Grid item className={classes.outergrid}>
                                <Switch>
                                    <Route path={`${match.path}/impressum`} component={() => <Impressum></Impressum>} />
                                    <Route path={`${match.path}/privacy`} component={() => <Privacy></Privacy>} />
                                    <Route path={`${match.path}/successful`} component={SignedSuccessful} />
                                    <Route path={`${match.path}`} render={() => <Signing baseUrl={redirectUrl}></Signing>} />
                                </Switch>
                            </Grid>
                        </Grid>
                    </div>
                    <Footer />
                    </SnackbarProvider>
                </MuiThemeProvider>
            );
        } else {
            return <div />
        }
    }
}

export default withRouter(withStyles(styles)(SignVmRouter));
