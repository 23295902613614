import React, { useState, useEffect } from 'react';
import {
    withStyles,
    Grid,
    TextField,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import {FF1Colors} from "../../themes/global-colors";

const styles = theme => ({
    vehicleRadios: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '15px',
        margin: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
        },
    },
    vehicleLabel: {
        flexGrow: 1,
        flexDirection: 'column',
        padding: '0.5rem',
        border: `1px solid ${FF1Colors.lightestGray}`,
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 130,
        '& img': {
            marginBottom: '1rem'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 160,
        },
    },
    vehicleLabelActive: {
        borderColor: FF1Colors.darkGray,
        background: FF1Colors.lightestGray,
    },
    licensePlate: {
        maxWidth: 400,

        '& input': {
            textAlign: 'center'
        },
        '& fieldset': {
            textAlign: 'center'
        }
    },
    hasError: {
        animation: "$shake 1s",
        '& fieldset': {
            borderColor: theme.palette.error.main + '!important',
        },
        '& input': {
            borderColor: theme.palette.error.main,
        }
    },
    "@keyframes shake": {
        "0%": { transform: "translateX(0)" },
        "15%": { transform: "translateX(-4px)" },
        "30%": { transform: "translateX(6px)" },
        "45%": { transform: "translateX(-4px)" },
        "60%": { transform: "translateX(6px)" },
        "100%": { transform: "translateX(0)" }
    }
});

const ParticipantVehicle = (props) => {
    const {classes, licensePlateKey, vehicleTypeKey, licensePlateLabel, vehicleTypeLabel, showErrors} = props;

    const [state, setState] = useState({
        [licensePlateKey]: props.state[licensePlateKey] || '',
        [vehicleTypeKey]: props.state[vehicleTypeKey] || props.vehicleTypes[0].value,
    });

    const isNormalVehicleType = () => {
        return state[vehicleTypeKey] !== 'Keine Angabe'
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
        validateForm()
    }

    useEffect(() => {
        props.stateChange(state);
    }, [state]);

    const validateForm = () => props.validationHandler(() => {
        if (isNormalVehicleType() && licensePlateLabel) {
            if (state[licensePlateKey].length > 0) {
                return true
            }
        } else {
            return true
        }

        return false
    });
    validateForm()

    return (
        <Grid container direction='column' justify='space-between'>
            <Grid item>
                <Grid container justify='center'>
                    <Grid item xs={12}>
                        <FormLabel component="legend">{vehicleTypeLabel}</FormLabel>
                        <RadioGroup aria-label="Fahrzeug"
                                    className={classes.vehicleRadios}
                                    name={vehicleTypeKey}
                                    value={state[vehicleTypeKey]}
                                    onChange={handleChange}>
                            {props.vehicleTypes.map((a, index) => {
                                let cssClasses = classes.vehicleLabel

                                if (state[vehicleTypeKey] === a.value) {
                                    cssClasses += ` ${classes.vehicleLabelActive}`
                                }

                                return (
                                    <FormControlLabel className={cssClasses} key={index}
                                                      value={a.value}
                                                      control={<Radio checkedIcon={<img alt='' src={a.icon}/>}
                                                                      icon={<img alt='' src={a.icon}/>} />}
                                                      label={a.label}/>
                                );
                            })}
                        </RadioGroup>
                    </Grid>

                    {licensePlateLabel &&
                    <Grid item xs={12}>
                    <Grid container alignItems='center' justify='center'>


                        <TextField
                            fullWidth
                            className={[classes.licensePlate, showErrors && !state[licensePlateKey] ? classes.hasError : "noClass"].join(" ")}
                            variant="outlined"
                            label={licensePlateLabel}
                            name={licensePlateKey}
                            value={state[licensePlateKey].toUpperCase()}
                            onChange={handleChange}
                            required={isNormalVehicleType()}
                            helperText={isNormalVehicleType() ? 'Beispiel: L-FF 123' : 'OPTIONAL'}
                        />
                    </Grid>
                    </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
};

export default withStyles(styles)(ParticipantVehicle);