import React from 'react';
import {List, ListItem, withStyles, Grid, Typography} from "@material-ui/core";
import {FF1Colors} from "../../themes/global-colors";

const styles = theme => ({
    type: {
        color: 'black',
        lineHeight: 1.3,
    },
    typeBold: {
        fontWeight: 700,
    },
    counterParent: {
        counterReset: 'list',
        paddingLeft: '2em',
    },
    counter: {
        counterIncrement: 'list',
        position: 'relative',

        "&::before": {
            content: `counter(list)`,
            position: 'absolute',
            top: '8px',
            left: '-15px',
            width: '20px',
            height: '20px',
            border: `1px solid ${FF1Colors.darkGray}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
        }
    },
    counterActive: {
        "&::before": {
            border: `1px solid ${theme.palette.secondary.main}`,
            background: theme.palette.secondary.main,
            color: 'white',
        }
    },
    counterSuccess: {
        "&::before": {
            content: '"✓"',
            border: `1px solid ${FF1Colors.greenFaded}`,
            background: FF1Colors.greenFaded,
            color: FF1Colors.darkGray,
        }
    }
});

const ProcessSigning = (props) => {
    const {classes, showPoaSuccess, showCaa} = props;

    const firstItemClass = () => {
        if (showPoaSuccess || showCaa) {
            return classes.counterSuccess;
        } else {
            return classes.counterActive;
        }
    }
    const secondItemClass = () => {
        if (showCaa) {
            return classes.counterActive;
        }
        return false
    }

    return (
        <Grid container justify='flex-start'>
            <Grid item>
                <Typography paragraph className={[classes.type, classes.typeBold].join(' ')} variant='h6' align='left'>
                    Beauftragen Sie uns jetzt kostenlos mit der Regulierung Ihres Unfalls.</Typography>
                <Typography variant='body2' align='left'>Um für Sie tätig werden zu können, benötigen wir zwei Unterschriften von Ihnen:</Typography>
                <List className={classes.counterParent}>
                    <ListItem className={[classes.counter, firstItemClass()].join(' ')}>Mit der ersten Unterschrift erlauben Sie uns, Sie für diesen Unfall gegenüber der gegnerischen Versicherung zu vertreten.</ListItem>
                    <ListItem className={[classes.counter, secondItemClass()].join(' ')}>Mit der zweiten Unterschrift stellen Sie sicher, dass für Sie keine Kosten entstehen und wir Rechnungen direkt an die gegnerische Versicherung stellen können.</ListItem>
                </List>
            </Grid>
        </Grid>
    )
};

export default withStyles(styles)(ProcessSigning);