import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {
    Icon,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    done: {
        background: theme.palette.primary.main,
        borderRadius: '100px',
        color: 'white',
    },
    clear: {
        opacity: 0.5,
    },
}));

export default (props) => {
    const classes = useStyles();
    const [activeStep] = React.useState(props.activeStep);


    return (
        <div className={classes.root}>
            {
                props.casedetails.steps &&
                <Stepper activeStep={activeStep} orientation="vertical">
                    {
                        props.casedetails.steps.map((step, index) => {
                            let icon = <Icon className={classes.clear}>clear</Icon>;
                            let tooltip = 'Noch nicht begonnen'
                            if (step.completed === true) {
                                icon = <Icon className={classes.done}>done</Icon>;
                                tooltip = 'Abgeschlossen';
                            } else if (step.active) {
                                tooltip = 'In Arbeit'
                                icon = <Icon>sync</Icon>
                            }
                            return (
                                <Step key={index} completed={step.completed} title={tooltip}>
                                    <StepLabel icon={icon}><span style={step.completed ? { fontWeight: 800 } : null}>{step.label}</span></StepLabel>
                                </Step>
                            )
                        })
                    }
                </Stepper>
            }
        </div>
    );
}