import React from 'react';
import {Grid, Typography, withStyles} from "@material-ui/core";
import {FF1Colors} from "../../themes/global-colors";

const styles = theme => ({
    toolTipWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap'
    },
    toolTipContent: {
        background: FF1Colors.lightestGray,
        padding: '.5rem',
    },
    toolTipTitle: {
        fontWeight: 'bold',
        fontSize: 12,
    },
    toolTipCopy: {
        fontSize: 12,
    },
    toolTipIcon: {
        background: FF1Colors.lightestGray,
        width: '20px',
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        marginRight: 10,
        flex: '0 0 auto'
    }
});


const InfoTooltip = props => {
    const {classes, title, copy} = props;

    return (
        <Grid container className={classes.toolTipWrapper}>
            <span className={classes.toolTipIcon}>?</span>
            <Grid container className={classes.toolTipContent}>
                <Typography className={classes.toolTipTitle}>{title}</Typography>
                <Typography className={classes.toolTipCopy}>{copy}</Typography>
            </Grid>
        </Grid>
    )
};

export default withStyles(styles)(InfoTooltip);